export const LOGIN = 'LOGIN';
export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT = 'LOGOUT';


export const LOADING_PRICES = "LOADING_PRICES";
export const LOAD_PRICES = "LOAD_PRICES";
export const CREATING_PRICES = "CREATING_PRICES";
export const CREATED_PRICES = "CREATED_PRICES";

export const UPDATING_PRICES = "UPDATING_PRICES";
export const UPDATED_PRICES = "UPDATED_PRICES";

export const DELETING_PRICES = "DELETING_PRICES";
export const DELETED_PRICES = "DELETED_PRICES";


export const PROJECTS_LOADING = "PROJECTS_LOADING";
export const PROJECTS_LOADED = "PROJECTS_LOADED";
export const PROJECT_UPDATING = "PROJECT_UPDATING";
export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_CREATE = "PROJECT_CREATE";



export const EMPLOYEES_LOADING = "EMPLOYEES_LOADING";
export const EMPLOYEES_LOADED = "EMPLOYEES_LOADED";
export const EMPLOYEE_LOADING = "EMPLOYEE_LOADING";
export const EMPLOYEE_LOADED = "EMPLOYEE_LOADED";
export const EMPLOYEE_DELETING = "EMPLOYEE_DELETING";
export const EMPLOYEE_CREATE = "EMPLOYEE_CREATE";


export const WORK_RECORDS_LOADING = "WORK_RECORDS_LOADING";
export const WORK_RECORDS_LOADED = "WORK_RECORDS_LOADED";
export const WORK_RECORD_DELETING = "WORK_RECORD_DELETING";
