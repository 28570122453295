import {WORK_RECORD_DELETING, WORK_RECORDS_LOADED, WORK_RECORDS_LOADING} from "./types";

const initialState = {
    loading: false,
    data: new Map()
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case WORK_RECORDS_LOADED:
            return {
                ...state,
                data: action.data,
                loading: false
            }

        case WORK_RECORDS_LOADING:
        case WORK_RECORD_DELETING:
            return {
                ...initialState,
                loading: true
            }

        default:
            return state;
    }
}