import {PROJECT_CREATE, PROJECT_DELETE, PROJECT_UPDATING, PROJECTS_LOADED, PROJECTS_LOADING} from "./types";

const initialState = {
    loading: false,
    data: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROJECTS_LOADED:
            return {
                ...state,
                data: action.data,
                loading: false
            }

        case PROJECTS_LOADING:
        case PROJECT_UPDATING:
        case PROJECT_CREATE:
        case PROJECT_DELETE:
            return {
                ...initialState,
                loading: true
            }

        default:
            return state;
    }
}