import {
    CREATED_PRICES,
    CREATING_PRICES,
    DELETED_PRICES, DELETING_PRICES,
    LOAD_PRICES,
    LOADING_PRICES,
    UPDATED_PRICES,
    UPDATING_PRICES
} from "./types";

const initialState = {
    loading: false,
    data: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PRICES:
            return {
                ...state,
                data: action.data,
                loading: false
            }

        case LOADING_PRICES:
            return {
                ...initialState,
                loading: true
            }

        case CREATED_PRICES:
        case UPDATED_PRICES:
        case DELETED_PRICES:
            return {
                ...state,
                data: action.data,
                loading: false
            }

        case CREATING_PRICES:
        case UPDATING_PRICES:
        case DELETING_PRICES:
            return {
                ...state,
                loading: true
            }

        default:
            return state;
    }
}