import {Navigate, Outlet} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {loginVerify} from "../../reducers/actions/authActions";

const PrivateRoute = () => {
    const state = useSelector((state) => state.auth, shallowEqual);
    const dispatch = useDispatch();
    dispatch(loginVerify());

    return (
        <>
            {
                state.user?.email ? (
                    <Outlet />
                )
                : <Navigate to="/auth/login" />
            }
        </>
    )
}

export default PrivateRoute;