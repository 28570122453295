import {FaTrashAlt} from "react-icons/fa";
import moment from "moment";
import Swal from "sweetalert2";
import {useDispatch} from "react-redux";
import {deleteWorkRecord} from "../../reducers/actions/workRecordActions";

const EmployeeRecordItem = (props) => {
    const { data, employeeId } = props;
    const dispatch = useDispatch();
    const date = new Date(data.date.seconds * 1000);

    const displayDeleteForm = (e, recordId) => {
        e.preventDefault();

        Swal.fire({
            title: `Eliminar registro`,
            text: 'Esta acción no se puede deshacer',
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deleteWorkRecord(employeeId, recordId));
            }
        });
    }

    return (
        <tr>
            <td>{ moment(date).format('DD/MM/yyyy') }</td>
            <td>{data.project.code}</td>
            <td>{data.project.name}</td>
            <td>{data.hours}</td>
            <td>{data.nightHours}</td>
            <td>{data.holidays}</td>
            <td>{data.money} €</td>
            <td>{data.abs}</td>
            <td>{data.observations}</td>
            <td>
                <button type="button" className="btn btn-danger btn-sm mr-1" onClick={(e) => displayDeleteForm(e, data.id)}>
                    <FaTrashAlt />
                </button>
            </td>
        </tr>
    );
}

export default EmployeeRecordItem;