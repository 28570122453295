import {firestore} from "../../firebase/config";
import {collection, deleteDoc, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {WORK_RECORD_DELETING, WORK_RECORDS_LOADED, WORK_RECORDS_LOADING} from "../types";

export function loadRecordByEmployeeId(employeeId) {
    return async (dispatch) => {
        dispatch(workRecordsLoading());

        const collectionRef = collection(firestore, "work_record");
        const employeeRef = doc(firestore, "employees", employeeId);
        const employeeDoc = await getDoc(employeeRef);

        const q = query(collectionRef, where("employee", "==", employeeRef));
        const docSnap = await getDocs(q);

        const projects = await getDocs(collection(firestore, "projects"));

        const records = new Map();

        docSnap.forEach(record => {
            const date = new Date(record.data().date.seconds * 1000);
            const key = date.getFullYear() + "-" + (date.getMonth() + 1);

            if (!records.has(key)) {
                records.set(key, []);
            }

            const workRecord = record.data();
            workRecord.employee = employeeDoc.data();
            workRecord.project = projects.docs.filter(project => project.id === workRecord.project.id).pop().data();
            workRecord.id = record.id;

            records.set(key, [
                ...records.get(key),
                workRecord
            ]);
        });

        dispatch(workRecordsLoaded(records));
    }
};

export function deleteWorkRecord(employeeId, workRecordId) {
    return async (dispatch) => {
        dispatch(workRecordDeleting());

        const docRef = await doc(firestore, "work_record", workRecordId);
        await deleteDoc(docRef);

        dispatch(loadRecordByEmployeeId(employeeId));
    }
}

const workRecordsLoading = () => ({
    type: WORK_RECORDS_LOADING
});

const workRecordsLoaded = (data) => ({
    type: WORK_RECORDS_LOADED,
    data: data
});

const workRecordDeleting = () => ({
    type: WORK_RECORD_DELETING
});
