import {Fragment, useEffect, useState} from "react";
import Navbar from "../layout/Navbar";
import {useDispatch, useSelector} from "react-redux";
import {loadEmployeeById, updateEmployee} from "../../reducers/actions/employeeActions";
import {Link, useParams} from "react-router-dom";
import {loadRecordByEmployeeId} from "../../reducers/actions/workRecordActions";
import SummaryRecordItem from "./SummaryRecordItem";
import {FaArrowLeft} from "react-icons/fa";

const EmployeeDetail = () => {
    const { employeeId } = useParams();
    const employeeState = useSelector((state) => state.employee);
    const workRecordsState = useSelector((state) => state.workRecords);

    const [employee, saveEmployee] = useState(employeeState);
    const [errors, setErrors] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        const loadData = () => {
            dispatch(loadEmployeeById(employeeId));
            dispatch(loadRecordByEmployeeId(employeeId));
        }
        loadData();
    }, [dispatch, employeeId]);

    useEffect(() => {
        saveEmployee(employeeState);
    }, [employeeState]);

    useEffect(() => {
    }, [workRecordsState]);

    const onChange = (e) => {
        saveEmployee({
            ...employee,
            [e.target.name]: e.target.value
        });

        switch (e.target.name) {
            case "name":
            case "firstSurname":
                if (e.target.value.length < 3 || e.target.value.length > 20) {
                    setFieldError(e.target.name, "El campo debe tener una longitud de entre 3 y 20 caracteres.");
                } else {
                    clearFieldError(e.target.name);
                }
                break;

            case "secondSurname":
                if (e.target.value.length > 0 && (e.target.value.length < 3 || e.target.value.length > 20)) {
                    setFieldError(e.target.name, "El campo debe tener una longitud de entre 3 y 20 caracteres.");
                } else {
                    clearFieldError(e.target.name);
                }
                break;

            case "dni":
                if (e.target.value.length != 9) {
                    setFieldError(e.target.name, "El campo debe tener una longitud de 9 caracteres.");
                } else {
                    clearFieldError(e.target.name);
                }
                break;

            default:
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(updateEmployee(employeeId, employee));
    }

    const setFieldError = (field, message) => {
        setErrors({
            ...errors,
            [field]: message
        });
    }

    const clearFieldError = (field) => {
        setErrors({
            ...errors,
            [field]: null
        })
    }

    return (
        <Fragment>
            <Navbar title={`Detalles de ${employeeState.name}`} tab_active="employees"/>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mt-0 header-title mb-4">Detalles de {employeeState.name}</h4>
                                        <div>
                                            <Link to={`/employees`}>
                                                <button type="button" className="btn btn-outline-secondary">
                                                    <FaArrowLeft/> &nbsp;
                                                    Volver
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group row">
                                            <label htmlFor="name"
                                                   className="col-sm-2 col-form-label">Nombre</label>
                                            <div className="col-sm-10">
                                                <input className={`form-control ${errors.name ? 'parsley-error' : ''}`}
                                                   id="name"
                                                   name="name"
                                                   type="text"
                                                   value={employee.name}
                                                   onChange={onChange} />
                                                { errors.name ?
                                                <ul className="parsley-errors-list filled">
                                                    <li className="parsley-required">{errors.name}</li>
                                                </ul>
                                                    : null }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="firstSurname"
                                                   className="col-sm-2 col-form-label">Apellido</label>
                                            <div className="col-sm-10">
                                                <input className={`form-control ${errors.firstSurname ? 'parsley-error' : ''}`}
                                                    id="firstSurname"
                                                    name="firstSurname"
                                                    type="text"
                                                    value={employee.firstSurname}
                                                    onChange={onChange} />
                                                { errors.firstSurname ?
                                                    <ul className="parsley-errors-list filled">
                                                        <li className="parsley-required">{errors.firstSurname}</li>
                                                    </ul>
                                                    : null }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="secondSurname"
                                                   className="col-sm-2 col-form-label">Segundo apellido</label>
                                            <div className="col-sm-10">
                                                <input className={`form-control ${errors.secondSurname ? 'parsley-error' : ''}`}
                                                       id="secondSurname"
                                                       name="secondSurname"
                                                       value={employee.secondSurname}
                                                       onChange={onChange}  />
                                                { errors.secondSurname ?
                                                    <ul className="parsley-errors-list filled">
                                                        <li className="parsley-required">{errors.secondSurname}</li>
                                                    </ul>
                                                    : null }
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="dni"
                                                   className="col-sm-2 col-form-label">DNI</label>
                                            <div className="col-sm-10">
                                                <input className={`form-control ${errors.dni ? 'parsley-error' : ''}`}
                                                       id="dni"
                                                       name="dni"
                                                       value={employee.dni}
                                                       onChange={onChange}  />
                                                { errors.dni ?
                                                    <ul className="parsley-errors-list filled">
                                                        <li className="parsley-required">{errors.dni}</li>
                                                    </ul>
                                                    : null }
                                            </div>
                                        </div>
                                        <div className="form-group mb-0">
                                            <div>
                                                <button type="submit" className="btn btn-primary waves-effect waves-light" disabled={employee.loading}>
                                                    Guardar
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title mb-4">Reportes mensuales</h4>
                                    <div className="table-responsive">
                                        <table className="table table-striped nowrap">
                                            <thead>
                                            <tr>
                                                <th style={{width: `10%`}}>Mes</th>
                                                <th>Horas</th>
                                                <th>Nocturnas</th>
                                                <th>Festivos</th>
                                                <th>Adelantos</th>
                                                <th>Absentismo</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {workRecordsState.loading ? (<tr>
                                                    <td colSpan="6">Cargando...</td>
                                                </tr>) : workRecordsState.data.size === 0 ?
                                                <tr><td colSpan="6">Sin reportes</td></tr> :
                                                Array.from(workRecordsState.data.keys()).map(entry => (
                                                    <SummaryRecordItem key={entry} month={entry} data={workRecordsState.data.get(entry)} employeeId={employeeId} />
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EmployeeDetail;