import {useNavigate} from "react-router-dom";
import moment from "moment";

const SummaryRecordItem = (props) => {
    const { data, month, employeeId } = props;
    const navigate = useNavigate();

    const hours = data.reduce((n, {hours}) => n + hours, 0);
    const nightHours = data.reduce((n, {nightHours}) => n + nightHours, 0);
    const holidays = data.reduce((n, {holidays}) => n + holidays, 0);
    const money = data.reduce((n, {money}) => n + money, 0);
    const abs = data.reduce((n, {abs}) => n + abs, 0);
    const date = new Date(month + '-01');

    const onEntryClick = () => {
        navigate(`/employees/${employeeId}/report/${month}`);
    }

    return (
        <tr onClick={onEntryClick} className="cursor-pointer">
            <td>{ moment(date).format('MMM yyyy') }</td>
            <td>{hours}</td>
            <td>{nightHours}</td>
            <td>{holidays}</td>
            <td>{money} €</td>
            <td>{abs}</td>
        </tr>
    );
}

export default SummaryRecordItem;