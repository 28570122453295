import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAZwgWPuPu9Tp30xh9G9IRb0S7W2pi363E",
    authDomain: "revyalb-mobile.firebaseapp.com",
    databaseURL: "https://revyalb-mobile-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "revyalb-mobile",
    storageBucket: "revyalb-mobile.appspot.com",
    messagingSenderId: "546126365100",
    appId: "1:546126365100:web:f152dbb089bd1b2413ba09",
    measurementId: "G-6SV3EBFC67"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);