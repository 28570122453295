import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store";
import React from "react";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/layout/PrivateRoute";
import Price from "./components/price/Price";
import Project from "./components/projects/Project";
import Employee from "./components/employees/Employee";
import EmployeeDetail from "./components/employees/EmployeeDetail";
import EmployeeRecords from "./components/employees/EmployeeRecords";

function App() {
    return (
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/" element={<PrivateRoute />}>
              <Route index element={<Price />} />
              <Route path="/projects" element={<Project />} />
              <Route path="/employees" element={<Employee />} />
              <Route path="/employees/:employeeId" element={<EmployeeDetail />} />
              <Route path="/employees/:employeeId/report/:month" element={<EmployeeRecords />} />
            </Route>
          </Routes>
        </Provider>
      </BrowserRouter>
  );
}

export default App;
