import {LOGIN, LOGIN_ERROR, LOGIN_PROCESS, LOGIN_SUCCESS, LOGOUT} from "./types";

const initalState = {
    loading: false,
    user: null,
    response: null
}

export default function reducer(state = initalState, action) {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                user: action.payload
            }

        case LOGIN_PROCESS:
            return {
                ...initalState,
                loading: true
            }

        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                response: action.response
            }

        case LOGIN_SUCCESS:
            return {
                ...initalState,
                user: action.payload
            }

        case LOGOUT:
            return {
                ...state,
            }

        default:
            return state;
    }
}