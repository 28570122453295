import {DEFAULT_TITLE} from "../../config/consts";

const Title = (title) => {
    if (title) {
        document.title = title + " | " + DEFAULT_TITLE;
    } else {
        document.title = DEFAULT_TITLE;
    }
};

export default Title;