import React, {useState} from "react";
import {
    MdAccountCircle,
    MdArrowDropDown,
    MdConstruction,
    MdContactSupport,
    MdDescription,
    MdLogout,
    MdPeople,
    MdSettings
} from "react-icons/md";

import Title from "../title";
import enFlag from "../../images/flags/us_flag.jpg"
import frFlag from "../../images/flags/french_flag.jpg"
import esFlag from "../../images/flags/spain_flag.jpg"
import itFlag from "../../images/flags/italy_flag.jpg"
import userAvatar from "../../images/users/user-1.jpg"
import {Link} from "react-router-dom";

const Navbar = (props) => {
    const jQuery = window.jQuery;

    const {title, tab_active} = props;

    Title(title);

    const [collapsed, setCollapse] = useState(true);

    const toggleNavbar = () => {
        setCollapse(!collapsed);
        jQuery('#navigation').toggleClass('d-block');
    };

    return (
        <div className="header-bg">
            <header id="topnav">
                <div className="topbar-main">
                    <div className="container-fluid">
                        <div className="menu-extras topbar-custom navbar">
                            <div id="navigation" className={collapsed ? "" : "d-block"}>
                                <ul className="navigation-menu">
                                    <li className={ tab_active === "dashboard" ? "has-submenu active" : "has-submenu" }>
                                        <Link to={"/"}>
                                            <MdDescription />
                                            Albaranes
                                        </Link>
                                    </li>
                                    <li className={ tab_active === "dashboard" ? "has-submenu active" : "has-submenu" }>
                                        <Link to={"/"}>
                                            <MdSettings />
                                            Partidas
                                        </Link>
                                    </li>
                                    <li className={ tab_active === "employees" ? "has-submenu active" : "has-submenu" }>
                                        <Link to={"/employees"}>
                                            <MdPeople />
                                            Empleados
                                        </Link>
                                    </li>
                                    <li className={ tab_active === "projects" ? "has-submenu active" : "has-submenu" }>
                                        <Link to={"/projects"}>
                                            <MdConstruction />
                                            Obras
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <ul className="navbar-right ml-auto list-inline float-right mb-0">
                                <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
                                    <Link className="nav-link dropdown-toggle arrow-none waves-effect"
                                       data-toggle="dropdown" to={"#"} role="button" aria-haspopup="false"
                                       aria-expanded="false">
                                        <img src={esFlag} className="mr-2" height="12"
                                             alt=""/> Español
                                        <MdArrowDropDown />
                                    </Link>
                                    <div
                                        className="dropdown-menu dropdown-menu-right dropdown-menu-animated language-switch">
                                        <Link className="dropdown-item" to={"#"}><img
                                            src={enFlag} alt=""
                                            height="16"/><span> English </span></Link>
                                        <Link className="dropdown-item" to={"#"}>
                                            <img src={frFlag} alt="" height="16"/><span> French </span>
                                        </Link>
                                        <Link className="dropdown-item" to={"#"}>
                                            <img src={itFlag} alt="" height="16"/>
                                            <span>Italiano</span>
                                        </Link>
                                    </div>
                                </li>

                                <li className="dropdown notification-list list-inline-item">
                                    <div className="dropdown notification-list nav-pro-img">
                                        <Link className="dropdown-toggle nav-link arrow-none waves-effect nav-user"
                                           data-toggle="dropdown" to={"#"} role="button" aria-haspopup="false"
                                           aria-expanded="false">
                                            <img src={userAvatar} alt="user"
                                                 className="rounded-circle" />
                                        </Link>
                                        <div
                                            className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
                                            <Link className="dropdown-item" to="#">
                                                <MdAccountCircle />
                                                Profile
                                            </Link>
                                            <Link className="dropdown-item d-block" to="#">
                                                <span className="badge badge-success float-right">11</span>
                                                <MdSettings />
                                                Settings
                                            </Link>
                                            <div className="dropdown-divider"></div>
                                            <Link className="dropdown-item text-danger" to="#">
                                                <MdLogout className="text-danger" />
                                                Logout
                                            </Link>
                                        </div>
                                    </div>
                                </li>

                                <li className="menu-item dropdown notification-list list-inline-item">
                                    <Link className={`navbar-toggle nav-link ${collapsed ? "" : "open"}`} onClick={toggleNavbar} to={"#"}>
                                        <div className="lines">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </header>
        </div>
    )
};

export default Navbar;