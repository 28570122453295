import {Fragment, useEffect, useState} from "react";
import Navbar from "../layout/Navbar";
import {FaPlus} from "react-icons/fa";
import Swal from "sweetalert2";
import PriceItem from "./PriceItem";
import {useDispatch, useSelector} from "react-redux";
import {createPrice, deletePrice, loadPrices, updatePrice} from "../../reducers/actions/priceActions";

const Price = () => {
    const [filter, setFilter] = useState();

    const state = useSelector((state) => state.prices);
    const dispatch = useDispatch();

    const onFilter = (e) => {
        const text = e.target.value;
        setFilter(text);

        if (!text) {
            setFilter(
                state.data
            );
        } else {
            setFilter(
                state.data.filter(input => input.code.toLowerCase().includes(text.toLowerCase()))
            );
        }
    };

    const displayForm = async (e, item) => {
        e.preventDefault();

        await Swal.fire({
            title: item == null ? "Nueva partida" : "Modificar partida",
            html:
                `<form role="form">` +
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="code">Código</label>` +
                            `<input type="text" id="code" class="form-control" value="${item != null ? item.code : ""}" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="name">Nombre</label>` +
                            `<input type="text" id="name" class="form-control" value="${item != null ? item.description : ""}" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="type">Tipo</label>` +
                            `<select class="form-control" id="type">` +
                                `<option value="work" ${item?.type === "work" ? "selected" : ""}>Trabajadores</option>` +
                                `<option value="material" ${item?.type === "material" ? "selected" : ""}>Materiales</option>` +
                                `<option value="transport" ${item?.type === "transport" ? "selected" : ""}>Transporte</option>` +
                            `</select>` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="form">Formulario</label>` +
                            `<select class="form-control" id="form" multiple>` +
                                `<option value="cam" ${item?.form.includes("cam") ? "selected" : ""}>CAM</option>` +
                                `<option value="act" ${item?.form.includes("act") ? "selected" : ""}>ACT</option>` +
                                `<option value="transport" ${item?.form.includes("transport") ? "selected" : ""}>Transporte</option>` +
                            `</select>` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="price">Precio</label>` +
                            `<input type="text" id="price" class="form-control" value="${item != null ? item.price : ""}" />` +
                        `</div>`+
                    `</div>`+
                '</form>',
            showCancelButton: true,
            confirmButtonText: item == null ? "Crear" :  "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const code = document.getElementById("code").value;
                const name = document.getElementById("name").value;
                const type = document.getElementById("type").value;
                const form = document.getElementById("form").value;
                const price = document.getElementById("price").value;

                let validation = state.data.filter((e) => e.code === code);
                if (item != null) {
                    if (item.code !== code && validation.length > 0) {
                        Swal.showValidationMessage(`Ya existe una partida con el código ${code}`)
                        return;
                    }
                } else {
                    if (validation.length > 0) {
                        Swal.showValidationMessage(`Ya existe una partida con el código ${code}`)
                        return;
                    }
                }

                if (code.trim() === "") {
                    Swal.showValidationMessage(`El código es obligatorio`);
                }

                else if (name.trim() === "") {
                    Swal.showValidationMessage(`El nombre es obligatorio`);
                }

                else if (type.trim() === "") {
                    Swal.showValidationMessage(`El tipo de partida es obligatorio`);
                }

                else if (form.trim() === "") {
                    Swal.showValidationMessage(`El formulario es obligatorio`);
                }

                else if (price.trim() === "" || isNaN(price) || parseFloat(price) < 0) {
                    Swal.showValidationMessage(`El precio es obligatorio`);
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = {
                    code: document.getElementById("code").value,
                    description: document.getElementById("name").value,
                    price: parseFloat(document.getElementById("price").value),
                    type: document.getElementById("type").value,
                    form: Array.from(document.getElementById('form').selectedOptions).map((e) => e.value)
                };

                if (item == null) {
                    dispatch(createPrice(state.data, data));
                } else {
                    dispatch(updatePrice(state.data, item.code, data));
                }
            }
        });
    }

    const displayDeleteForm = (e, code) => {
        e.preventDefault();

        Swal.fire({
            title: `Eliminar partida ${code}`,
            html:
                `<form role="form">` +
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="code">Escriba el código de la partida para confirmar</label>` +
                            `<input type="text" id="code" class="form-control" />` +
                        `</div>`+
                    `</div>`+
                `</form>`,
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const input = document.getElementById("code").value;
                if (code !== input) {
                    Swal.showValidationMessage("El código de la partida es incorrecto.")
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deletePrice(state.data, code));
            }
        });
    }

    useEffect(() => {
        const loadData = () => {
            dispatch(loadPrices());
        }

        loadData();
    }, [dispatch]);

    useEffect(() => {
        setFilter(state.data);
    }, [state.data]);

    return (
        <Fragment>
            <Navbar title="Partidas" tab_active="config"/>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mt-0 header-title mb-4">Listado de partidas</h4>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={displayForm}>
                                                <FaPlus/> &nbsp;
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Buscar código" onChange={onFilter} />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped nowrap">
                                            <thead>
                                            <tr>
                                                <th style={{width: `10%`}}>Código</th>
                                                <th>Nombre</th>
                                                <th>Tipo</th>
                                                <th>Formulario</th>
                                                <th>Precio</th>
                                                <th style={{width: `10%`}}></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {state.loading ? (<tr>
                                                    <td colSpan="6">Cargando...</td>
                                                </tr>) :
                                                filter?.length === 0 ? (<tr>
                                                        <td colSpan="6">No se encontraron partidas</td>
                                                    </tr>) :
                                                    filter?.map((entry) => (
                                                        <PriceItem key={entry.code} data={entry} displayForm={displayForm} displayDeleteForm={displayDeleteForm} />
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Price;