import {combineReducers} from "redux";
import LoginReducer from "./loginReducer"
import PricesReducer from "./priceReducer"
import ProjectsReducer from "./projectReducer"
import EmployeesReducer from "./employeesReducer"
import EmployeeReducer from "./employeeReducer"
import WorkRecordsReducer from "./workRecordsReducer"

export default combineReducers({
    auth: LoginReducer,
    prices: PricesReducer,
    projects: ProjectsReducer,
    employees: EmployeesReducer,
    employee: EmployeeReducer,
    workRecords: WorkRecordsReducer
});