import {firestore} from "../../firebase/config";
import {addDoc, collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, where} from "firebase/firestore";
import {
    EMPLOYEE_CREATE,
    EMPLOYEE_DELETING,
    EMPLOYEE_LOADED,
    EMPLOYEE_LOADING,
    EMPLOYEES_LOADED,
    EMPLOYEES_LOADING
} from "../types";

export function loadEmployees() {
    return async (dispatch) => {
        dispatch(employeesLoading());

        const collectionRef = collection(firestore, "employees");
        const q = query(collectionRef, orderBy("name"));
        const docSnap = await getDocs(q);

        dispatch(employeesLoaded(docSnap.docs));
    }
};

export function loadEmployeeById(id) {
    return async (dispatch) => {
        dispatch(employeeLoading());

        const document = doc(firestore, "employees", id);
        const docSnap = await getDoc(document);

        dispatch(employeeLoaded(docSnap.data()));
    }
};

export function updateEmployee(id, data) {
    return async  (dispatch) => {
        dispatch(employeeLoading());

        const document = doc(firestore, "employees", id);

        await setDoc(document, {
            name: data.name,
            firstSurname: data.firstSurname,
            secondSurname: data.secondSurname ?? '',
            dni: data.dni,
            deleted: false
        });

        dispatch(employeeLoaded(data));
    }
}

export function deleteEmployee(employeeId) {
    return async  (dispatch) => {
        dispatch(employeeDeleting());

        const workRef = collection(firestore, "work_record");
        const employeeRef = await doc(firestore, "employees", employeeId);
        const q = query(workRef, where("employee", "==", employeeRef));
        const worksDocuments = await getDocs(q);

        await worksDocuments.forEach(result => deleteDoc(result.ref));
        await deleteDoc(employeeRef);

        dispatch(loadEmployees());
    }
}

export function createEmployee(data) {
    return async (dispatch) => {
        dispatch(employeeCreate());

        await addDoc(collection(firestore, "employees"), data);

        dispatch(loadEmployees());
    }
};

const employeeLoading = () => ({
    type: EMPLOYEE_LOADING
});

const employeeCreate = () => ({
    type: EMPLOYEE_CREATE
});

const employeeDeleting = () => ({
    type: EMPLOYEE_DELETING
});

const employeesLoading = () => ({
    type: EMPLOYEES_LOADING
});

const employeesLoaded = (data) => ({
    type: EMPLOYEES_LOADED,
    data: data
});

const employeeLoaded = (data) => ({
    type: EMPLOYEE_LOADED,
    name: data.name,
    firstSurname: data.firstSurname,
    secondSurname: data.secondSurname,
    dni: data.dni,
});