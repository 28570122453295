import {LOGIN_ERROR, LOGIN_PROCESS, LOGIN_SUCCESS, LOGOUT} from "../types";
import {auth} from "../../firebase/config";
import {onAuthStateChanged, signInWithEmailAndPassword, signOut} from "firebase/auth";

export function loginAction(login) {
    return async (dispatch) => {
        await dispatch(loginProcess());
        try {
            const {user} = await signInWithEmailAndPassword(
                auth,
                login.email,
                login.password
            );
            dispatch(loginSuccess(user));
        } catch (error) {
            let payload = {
                type: "warning",
                message: "Los datos de inicio de sesión son incorrectos"
            }
            dispatch(loginError(payload));
        }
    }
}

export function loginVerify() {
    return async (dispatch) => {
        onAuthStateChanged(auth, user => {
           if (user) {
               dispatch(loginSuccess(user))
           }  else {
               logoutAction();
           }
        });
    }
}

export function logoutAction() {
    return async (dispatch) => {
        await signOut(auth);
        dispatch(logout());
    }
}

const loginProcess = () => ({
    type: LOGIN_PROCESS,
});

const logout = () => ({
    type: LOGOUT
});

const loginError = (response) => ({
    type: LOGIN_ERROR,
    response: response
});

const loginSuccess = (payload) => ({
    type: LOGIN_SUCCESS,
    payload: payload
});