import {firestore} from "../../firebase/config";
import {collection, deleteDoc, doc, getDocs, orderBy, query, updateDoc, addDoc} from "firebase/firestore";
import {PROJECT_CREATE, PROJECT_DELETE, PROJECT_UPDATING, PROJECTS_LOADED, PROJECTS_LOADING} from "../types";

export function loadProjects() {
    return async (dispatch) => {
        dispatch(projectsLoading());

        const collectionRef = collection(firestore, "projects");
        const q = query(collectionRef, orderBy("code"));
        const docSnap = await getDocs(q);

        dispatch(projectsLoaded(docSnap.docs));
    }
};

export function updateProject(projectId, data) {
    return async (dispatch) => {
        dispatch(projectUpdating());

        const docRef = await doc(firestore, "projects", projectId);
        await updateDoc(docRef, data);

        dispatch(loadProjects());
    }
};

export function createProject(data) {
    return async (dispatch) => {
        dispatch(projectUpdating());

        await addDoc(collection(firestore, "projects"), data);

        dispatch(loadProjects());
    }
};

export function deleteProject(projectId) {
    return async (dispatch) => {
        dispatch(projectDelete());

        const docRef = await doc(firestore, "projects", projectId);
        await deleteDoc(docRef);

        dispatch(loadProjects());
    }
}

const projectsLoading = () => ({
    type: PROJECTS_LOADING
});

const projectUpdating = () => ({
    type: PROJECT_UPDATING
});

const projectCreate = () => ({
    type: PROJECT_CREATE
});

const projectDelete = () => ({
    type: PROJECT_DELETE
});

const projectsLoaded = (data) => ({
    type: PROJECTS_LOADED,
    data: data
});