import {Fragment, useEffect, useState} from "react";
import Navbar from "../layout/Navbar";
import ProjectItem from "./ProjectItem";
import {useDispatch, useSelector} from "react-redux";
import {createProject, loadProjects, updateProject} from "../../reducers/actions/projectActions";
import Swal from "sweetalert2";
import {createPrice} from "../../reducers/actions/priceActions";
import {FaPlus} from "react-icons/fa";

const Project = () => {
    const [filter, setFilter] = useState();

    const state = useSelector((state) => state.projects);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadData = () => {
            dispatch(loadProjects());
        }

        loadData();
    }, [dispatch]);

    useEffect(() => {
        setFilter(state.data);
    }, [state.data]);

    const onFilter = (e) => {
        const text = e.target.value;

        setFilter(text);
        if (!text) {
            setFilter(
                state.data
            );
        } else {
            setFilter(
                state.data.filter(input => input.data().code.toLowerCase().includes(text.toLowerCase()))
            );
        }
    };

    const displayForm = async (e, id, item) => {
        e.preventDefault();

        await Swal.fire({
            title: item == null ? "Nueva obra" : "Modificar obra",
            html:
                `<form role="form">` +
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="code">Código</label>` +
                            `<input type="text" id="code" class="form-control" value="${id != null ? item.code : ""}" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="name">Nombre</label>` +
                            `<input type="text" id="name" class="form-control" value="${id != null ? item.name : ""}" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="address">Dirección</label>` +
                            `<input type="text" id="address" class="form-control" value="${id != null ? item.address : ""}" />` +
                        `</div>`+
                    `</div>`+
                '</form>',
            showCancelButton: true,
            confirmButtonText: id == null ? "Crear" :  "Guardar",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const code = document.getElementById("code").value;
                const name = document.getElementById("name").value;
                const address = document.getElementById("address").value;

                if (code.trim() === "") {
                    Swal.showValidationMessage(`El código es obligatorio`);
                }

                else if (name.trim() === "") {
                    Swal.showValidationMessage(`El nombre es obligatorio`);
                }

                else if (address.trim() === "") {
                    Swal.showValidationMessage(`La dirección es obligatoria`);
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = {
                    code: document.getElementById("code").value.trim(),
                    name: document.getElementById("name").value.trim(),
                    address: document.getElementById("address").value.trim()
                };

                if (id == null) {
                    dispatch(createProject(data));
                } else {
                    dispatch(updateProject(id, data));
                }
            }
        });
    }

    return (
        <Fragment>
            <Navbar title="Obras" tab_active="projects"/>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mt-0 header-title mb-4">Listado de de obras</h4>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={displayForm} disabled={state.loading}>
                                                <FaPlus/> &nbsp;
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Buscar código" onChange={onFilter} />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped nowrap">
                                            <thead>
                                            <tr>
                                                <th style={{width: `10%`}}>Código</th>
                                                <th>Nombre</th>
                                                <th>Dirección</th>
                                                <th style={{width: `10%`}}></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {state.loading ? (<tr>
                                                    <td colSpan="4">Cargando...</td>
                                                </tr>) :
                                                filter?.length === 0 ? (<tr>
                                                        <td colSpan="4">No se encontraron obras</td>
                                                    </tr>) :
                                                    filter?.map((entry) => (
                                                        <ProjectItem key={entry.id} data={entry} displayForm={displayForm} />
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Project;