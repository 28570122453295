import {FaEdit, FaTrashAlt} from "react-icons/fa";
import {Link} from "react-router-dom";
import Swal from "sweetalert2";
import {deleteEmployee} from "../../reducers/actions/employeeActions";
import {useDispatch} from "react-redux";

const EmployeeItem = (props) => {
    const dispatch = useDispatch();
    const { data } = props;

    const displayDeleteForm = (e) => {
        e.preventDefault();

        Swal.fire({
            title: `Eliminar empleado`,
            text: 'Esta acción no se puede deshacer',
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deleteEmployee(data.id));
            }
        });
    }

    return (
        <tr>
            <td>{ data.data().name }</td>
            <td>{ data.data().firstSurname } { data.data().secondSurname }</td>
            <td>{ data.data().dni }</td>
            <td>
                <Link to={`${data.id}`}>
                    <button type="button" className="btn btn-warning btn-sm mr-1">
                        <FaEdit />
                    </button>
                </Link>
                <button type="button" className="btn btn-danger btn-sm" onClick={(e) => displayDeleteForm(e)}>
                    <FaTrashAlt />
                </button>
            </td>
        </tr>
    );
}

export default EmployeeItem;