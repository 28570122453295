import {EMPLOYEE_DELETING, EMPLOYEE_LOADED, EMPLOYEE_LOADING} from "./types";

const initialState = {
    loading: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EMPLOYEE_LOADED:
            return {
                ...state,
                ...action,
                loading: false
            }

        case EMPLOYEE_LOADING:
        case EMPLOYEE_DELETING:
            return {
                ...initialState,
                loading: true
            }

        default:
            return state;
    }
}