import {EMPLOYEE_CREATE, EMPLOYEE_DELETING, EMPLOYEES_LOADED, EMPLOYEES_LOADING} from "./types";

const initialState = {
    loading: false,
    data: []
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EMPLOYEES_LOADED:
            return {
                ...state,
                data: action.data,
                loading: false
            }

        case EMPLOYEES_LOADING:
        case EMPLOYEE_CREATE:
        case EMPLOYEE_DELETING:
            return {
                ...initialState,
                loading: true
            }

        default:
            return state;
    }
}