import {firestore} from "../../firebase/config";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {
    CREATED_PRICES,
    CREATING_PRICES, DELETED_PRICES,
    DELETING_PRICES,
    LOAD_PRICES,
    LOADING_PRICES,
    UPDATED_PRICES,
    UPDATING_PRICES
} from "../types";
import Swal from "sweetalert2";

export function loadPrices() {
    return async (dispatch) => {
        dispatch(pricesLoading());

        const document = doc(firestore, "prices", "B67228742");
        const docSnap = await getDoc(document);

        dispatch(pricesLoaded(docSnap.data().data));
    }
};

export function createPrice(currentData, data) {
    return async  (dispatch) => {
        dispatch(priceCreating());

        const document = doc(firestore, "prices", "B67228742");
        currentData = {
            data: [
                ...currentData,
                data
            ]
        };


        await setDoc(document, currentData);
        await Swal.fire(
            "Partida creada",
            `La partida ${data.code} fue creada correctamente`,
            "success"
        );

        dispatch(priceCreated(currentData.data));
    }
}

export function updatePrice(currentData, code, data) {
    return async  (dispatch) => {
        dispatch(priceUpdating());

        const item = currentData.findIndex((e) => e.code === code);
        currentData[item] = data;

        const document = doc(firestore, "prices", "B67228742");
        currentData = {
            data: [
                ...currentData
            ]
        };

        await setDoc(document, currentData);
        await Swal.fire(
            "Partida actualizada",
            `La partida ${data.code} fue actualizada correctamente`,
            "success"
        );

        dispatch(priceUpdated(currentData.data));
    }
}

export function deletePrice(currentData, code) {
    return async  (dispatch) => {
        dispatch(priceDeleting());

        const items = currentData.filter((e) => e.code !== code);
        const document = doc(firestore, "prices", "B67228742");
        currentData = {
            data: [
                ...items
            ]
        };

        await setDoc(document, currentData);
        await Swal.fire(
            "Partida eliminada",
            `La partida ${code} fue eliminada correctamente`,
            "success"
        );

        dispatch(priceDeleted(currentData.data));
    }
}
const pricesLoading = () => ({
    type: LOADING_PRICES
});

const pricesLoaded = (data) => ({
    type: LOAD_PRICES,
    data: data
});

const priceCreating = () => ({
    type: CREATING_PRICES
});

const priceCreated = (data) => ({
    type: CREATED_PRICES,
    data: data
});

const priceUpdating = () => ({
    type: UPDATING_PRICES
});

const priceUpdated = (data) => ({
    type: UPDATED_PRICES,
    data: data
});

const priceDeleting = () => ({
    type: DELETING_PRICES
});

const priceDeleted = (data) => ({
    type: DELETED_PRICES,
    data: data
});