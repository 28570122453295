import React from "react";

const Alert = (props ) => {
    const className = props.type === 'error' ? "alert-danger" : props.type === 'warning' ? "alert-warning" : "alert-success"

    return (
        <div className={`alert alert-white rounded ${className}`} role="alert">
            {props.message}
        </div>
    )
};

export default Alert;