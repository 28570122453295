import {FaEdit, FaTrashAlt} from "react-icons/fa";
import Swal from "sweetalert2";
import {deleteWorkRecord} from "../../reducers/actions/workRecordActions";
import {useDispatch} from "react-redux";
import {deleteProject} from "../../reducers/actions/projectActions";

const ProjectItem = (props) => {
    const { data, displayForm } = props;
    const dispatch = useDispatch();

    const displayDeleteForm = (e, projectId) => {
        e.preventDefault();

        Swal.fire({
            title: `Eliminar obra`,
            text: 'Esta acción no se puede deshacer',
            showCancelButton: true,
            confirmButtonText: "Eliminar",
            cancelButtonText: "Cancelar",
        }).then(async (result) => {
            if (result.isConfirmed) {
                dispatch(deleteProject(projectId));
            }
        });
    }

    return (
        <tr>
            <td>{ data.data().code }</td>
            <td>{ data.data().name }</td>
            <td>{ data.data().address }</td>
            <td>
                <button type="button" className="btn btn-warning btn-sm mr-1" onClick={(e) => displayForm(e, data.id, data.data())}>
                    <FaEdit />
                </button>
                <button type="button" className="btn btn-danger btn-sm" onClick={(e) => displayDeleteForm(e, data.id)}>
                    <FaTrashAlt />
                </button>
            </td>
        </tr>
    );
}

export default ProjectItem;