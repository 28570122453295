import {Link, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import Navbar from "../layout/Navbar";
import {Fragment} from "react";
import moment from "moment/moment";
import {FaArrowLeft, FaFileExcel} from "react-icons/fa";
import EmployeeRecordItem from "./EmployeeRecordItem";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";

const EmployeeRecords = () => {
    const { employeeId, month } = useParams();
    const employeeState = useSelector((state) => state.employee);
    const workRecordsState = useSelector((state) => state.workRecords);
    const date = new Date(month + '-01');

    const monthData = workRecordsState.data.get(month) !== undefined ? workRecordsState.data.get(month) : null;

    const data = monthData != null ? monthData.sort((a, b) => {
        if (a.date < b.date)
            return -1;

        if (a.date > b.date)
            return 1;

        return 0;
    }) : null;

    const exportToExcel = async () => {
        const workSheetData = [];
        data.forEach(record => {
           workSheetData.push(
               {
                   name: record.employee.name.concat(' ').concat(record.employee.firstSurname).concat(' ').concat(record.employee.secondSurname),
                   date: moment(new Date(record.date.seconds * 1000)).format('DD/MM/yyyy'),
                   projectCode: record.project.code,
                   projectName: record.project.name,
                   hours: record.hours,
                   nightHours: record.nightHours,
                   holidays: record.holidays,
                   money: record.money,
                   abs: record.abs,
                   observations: record.observations
                }
           );
        });

        const workBook = new ExcelJS.Workbook();
        const workSheet = workBook.addWorksheet("Reporte");
        const style = {
            fill: { type: 'pattern', pattern: 'solid', bgColor: { argb: '000000' } },
            font: { bold: true, color: { argb: 'ffffff'} }
        };
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1'].map(key => workSheet.getCell(key).style = style);

        workSheet.columns = [
            { header: 'Fecha', key: 'date', width: 15 },
            { header: 'Cod. obra', key: 'projectCode', width: 15 },
            { header: 'Obra', key: 'projectName', width: 50 },
            { header: 'Horas', key: 'hours', width: 15 },
            { header: 'Nocturnas', key: 'nightHours', width: 15 },
            { header: 'Festivos', key: 'holidays', width: 15 },
            { header: 'Adelantos', key: 'money', width: 15 },
            { header: 'Absentismo', key: 'abs', width: 15 },
            { header: 'Observaciones', key: 'observations', width: 50 }
        ];

        workSheet.addRows(workSheetData);
        workSheet.addRow([]);
        workSheet.addRow(['', 'Mes', moment(date).format('MMM yyyy')]).getCell(2).font = { bold: true };
        workSheet.addRow(['', 'Empleado', employeeState.name + ' ' + employeeState.firstSurname + ' ' + employeeState.secondSurname]).getCell(2).font = { bold: true };
        workSheet.addRow(['', 'DNI', employeeState.dni]).getCell(2).font = { bold: true };
        workSheet.addRow(['', 'Firma']).getCell(2).font = { bold: true };

        const buffer = await workBook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const blob = new Blob([buffer], { type: fileType });
        FileSaver.saveAs(blob, `${employeeState.firstSurname}_${moment(date).format('MMMyyyy')}.xlsx`);
    }

    return (
        <Fragment>
            <Navbar title={`Reporte mensual de ${employeeState.name}`} tab_active="employees"/>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mt-0 header-title mb-4">Reporte mensual de {employeeState.name} para { moment(date).format('MMM yyyy') }</h4>
                                        <div>
                                            <button type="button" className="btn btn-success mr-1" onClick={exportToExcel} disabled={data === null}>
                                                <FaFileExcel/> &nbsp;
                                                Descargar
                                            </button>
                                            <Link to={`/employees/${employeeId}`}>
                                                <button type="button" className="btn btn-outline-secondary">
                                                    <FaArrowLeft/> &nbsp;
                                                    Volver
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped nowrap">
                                            <thead>
                                            <tr>
                                                <th style={{width: `10%`}}>Fecha</th>
                                                <th>Cod. Obra</th>
                                                <th>Obra</th>
                                                <th>Horas</th>
                                                <th>Nocturnas</th>
                                                <th>Festivos</th>
                                                <th>Adelantos</th>
                                                <th>Absentismo</th>
                                                <th>Observaciones</th>
                                                <th style={{width: `10%`}}></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {workRecordsState.loading ? (<tr>
                                                <td colSpan="10">Cargando...</td>
                                            </tr>) : data === null ?
                                                <tr><td colSpan="10">Sin reportes</td></tr> :
                                                data.map(entry => (
                                                        <EmployeeRecordItem key={entry.id} data={entry} employeeId={employeeId} />
                                                    ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default EmployeeRecords;