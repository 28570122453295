import {Fragment, useState} from "react";
import {FaLock} from "react-icons/fa";
import Title from "../title";
import {useDispatch, useSelector} from "react-redux";
import {Link, Navigate} from "react-router-dom";
import {loginAction} from "../../reducers/actions/authActions";
import Alert from "../alert/Alert";

const Login = () => {
    Title("Iniciar sesión");

    const [user, saveUser] = useState({
        username: '',
        password: ''
    });

    const dispatch = useDispatch();
    const state = useSelector((state) => state.auth);

    const onChange = (e) => {
        saveUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const doLogin = (user) => {
        dispatch(loginAction(user));
    }

    const onSubmit = (e) => {
        e.preventDefault();
        doLogin(user);
        saveUser({
            ...user,
            password: ""
        });
    }

    return state.user?.email ? ( <Navigate to="/" /> ) : (
        <Fragment>
            <div className="accountbg"></div>
            <div className="wrapper-page">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5">
                            <div className="card card-pages shadow-none mt-4">
                                <div className="card-body">
                                    <div className="text-center mt-0 mb-3">
                                        <Link to="/" className="logo logo-admin">
                                            <img src="http://www.revyalb.es/images/logo.png" className="mt-3" alt="" />
                                        </Link>
                                        <p className="text-muted w-75 mx-auto mb-4 mt-4">Inicie sesión para continuar</p>
                                    </div>

                                    <form className="form-horizontal mt-4" method="post" onSubmit={onSubmit}>
                                        { state.response && state.response.message ? (
                                            <Alert type={state.response.type} message={state.response.message} />
                                        ) : null }
                                        <div className="form-group">
                                            <div className="col-12">
                                                <label htmlFor="username">E-mail</label>
                                                <input type="email" placeholder="E-mail" name="email" id="email" className="form-control" required
                                                       value={user.email}
                                                       onChange={onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="col-12">
                                                <label htmlFor="password">Contraseña</label>
                                                <input type="password" placeholder="Password" name="password" id="password" className="form-control" required
                                                       value={user.password}
                                                       onChange={onChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group text-center mt-3">
                                            <div className="col-12">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" disabled={state.loading}
                                                        type="submit">Iniciar sesión
                                                </button>
                                            </div>
                                        </div>

                                        <div className="form-group text-center mt-4">
                                            <div className="col-12">
                                                <div className="text-center">
                                                    <a href="pages-recoverpw.html" className="text-muted">
                                                        <FaLock className="mr-1" />
                                                        Contraseña olvidada?
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Login;