import {FaEdit, FaTrashAlt} from "react-icons/fa";

const PriceItem = (props) => {
    const { data, displayForm, displayDeleteForm } = props;
    return (
        <tr>
            <td>{ data.code }</td>
            <td>{ data.description }</td>
            <td>{ data.type === "work" ? "Trabajadores" : data.type === "material" ? "Materiales" : "Transporte" }</td>
            <td>{ data.form == null ? "Todos" : data.form.join(", ") }</td>
            <td>{ data.price.toFixed(2) }</td>
            <td>
                <button type="button" className="btn btn-warning btn-sm mr-1" onClick={(e) => displayForm(e, data)}>
                    <FaEdit />
                </button>
                <button type="button" className="btn btn-danger btn-sm" onClick={(e) => displayDeleteForm(e, data.code)}>
                    <FaTrashAlt />
                </button>
            </td>
        </tr>
    );
}

export default PriceItem;