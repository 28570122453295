import {Fragment, useEffect, useState} from "react";
import Navbar from "../layout/Navbar";
import EmployeeItem from "./EmployeeItem";
import {useDispatch, useSelector} from "react-redux";
import {createEmployee, loadEmployees} from "../../reducers/actions/employeeActions";
import Swal from "sweetalert2";
import {FaPlus} from "react-icons/fa";

const Employee = () => {
    const [filter, setFilter] = useState();

    const state = useSelector((state) => state.employees);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadData = () => {
            dispatch(loadEmployees());
        }

        loadData();
    }, [dispatch]);

    useEffect(() => {
        setFilter(state.data);
    }, [state.data]);

    const onFilter = (e) => {
        const text = e.target.value;
        setFilter(text);

        if (!text) {
            setFilter(
                state.data
            );
        } else {
            setFilter(
                state.data.filter(input => input.data().name.toLowerCase().includes(text.toLowerCase()) || input.data().firstSurname.toLowerCase().includes(text.toLowerCase()))
            );
        }
    };

    const displayForm = async (e) => {
        e.preventDefault();

        await Swal.fire({
            title: "Nuevo empleado",
            html:
                `<form role="form">` +
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="name">Nombre</label>` +
                            `<input type="text" id="name" class="form-control" value="" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="firstSurname">Apellido</label>` +
                            `<input type="text" id="firstSurname" class="form-control" value="" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="secondSurname">Segundo apellido</label>` +
                            `<input type="text" id="secondSurname" class="form-control" value="" />` +
                        `</div>`+
                    `</div>`+
                    `<div class="form-group">` +
                        `<div class="col-12 text-left">` +
                            `<label for="dni">DNI</label>` +
                            `<input type="text" id="dni" class="form-control" value="" />` +
                        `</div>`+
                    `</div>`+
                '</form>',
            showCancelButton: true,
            confirmButtonText: "Crear",
            cancelButtonText: "Cancelar",
            preConfirm: () => {
                const name = document.getElementById("name").value;
                const firstSurname = document.getElementById("firstSurname").value;
                const dni = document.getElementById("dni").value;

                if (name.trim() === "") {
                    Swal.showValidationMessage(`El nombre es obligatorio`);
                }

                else if (firstSurname.trim() === "") {
                    Swal.showValidationMessage(`El primer apellido es obligatorio`);
                }

                else if (dni.trim().length !== 9) {
                    Swal.showValidationMessage(`El DNI es obligatorio`);
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                const data = {
                    name: document.getElementById("name").value.trim(),
                    firstSurname: document.getElementById("firstSurname").value.trim(),
                    secondSurname: document.getElementById("secondSurname").value.trim(),
                    dni: document.getElementById("dni").value.trim(),
                    deleted: false
                };

                dispatch(createEmployee(data));
            }
        });
    }

    return (
        <Fragment>
            <Navbar title="Empleados" tab_active="employees"/>
            <div className="wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mt-0 header-title mb-4">Lista de empleados</h4>
                                        <div>
                                            <button type="button" className="btn btn-primary" onClick={displayForm} disabled={state.loading}>
                                                <FaPlus/> &nbsp;
                                                Agregar
                                            </button>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" placeholder="Buscar empleado" onChange={onFilter} />
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped nowrap">
                                            <thead>
                                            <tr>
                                                <th>Nombre</th>
                                                <th>Apellido</th>
                                                <th>DNI</th>
                                                <th style={{width: `10%`}}></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {state.loading ? (<tr>
                                                    <td colSpan="4">Cargando...</td>
                                                </tr>) :
                                                filter?.sort((a, b) => {
                                                    if (a.data().name.toLowerCase() < b.data().name.toLowerCase())
                                                        return -1;

                                                    if (a.data().name.toLowerCase() > b.data().name.toLowerCase())
                                                        return 1;

                                                    return 0;
                                                })
                                                .map((entry) => (
                                                    <EmployeeItem key={entry.id} data={entry} />
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Employee;